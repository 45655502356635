import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    width: String
  }

  connect() {
    var options = {};
    if (this.hasWidthValue) {
      options["width"] = this.widthValue;
    }
    $(this.element).select2(options);
  }
}
